import { TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

const Estimator = () => {
  const [area, setArea] = useState(0);
  const [range, setRange] = useState(100);
  const [beacons, setBeacons] = useState();

  useEffect(() => {
    function calculateCircleArea(diameter) {
      const radius = diameter / 2;
      const area = Math.PI * Math.pow(radius, 2);
      return area;
    }

    const beaconArea = calculateCircleArea(range);
    console.log(beaconArea);

    const res = Math.ceil(area / beaconArea);
    console.log(res);
    setBeacons(res);
  }, [area, range]);

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div
        style={{
          backgroundColor: "white",
          marginTop: "10px",
          borderRadius: "30px",
          boxShadow: "15px 15px 19px rgba(0, 0, 0, 0.1)",
          padding: "20px 20px 40px 20px",
          width: "clamp(400px, 60%, 600px)",
          marginBottom: "50px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "nowrap",
            justifyContent: "space-evenly",
          }}
        >
          <img
            src="/assets/teldio_logo.png"
            alt="Teldio Logo"
            style={{ paddingTop: "20px", maxWidth: "200px", height: "auto" }}
          />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <TextField
            sx={{ marginTop: "40px", width: "60%", fontFamily: "inherit" }}
            label={`Area of Location (sq ft)`}
            type="number"
            value={area}
            autoComplete="off"
            onChange={(e) => setArea(e.target.value)}
          />
          <TextField
            sx={{ marginTop: "20px", width: "60%", fontFamily: "inherit" }}
            label={`Beacon Range (ft)`}
            type="number"
            value={range}
            autoComplete="off"
            onChange={(e) => setRange(e.target.value)}
          />

          {!isNaN(beacons) && beacons !== 0 && beacons !== Infinity ? (
            <>
              {" "}
              <Typography sx={{ marginTop: "40px" }} variant="h5">
                The number of beacons required is: {beacons}*
              </Typography>
              <Typography sx={{ marginTop: "10px", textAlign: "center", color: "darkgrey" }} variant="subtitle2">
                *Maximum Beacon Range is 100ft Radius in open areas with no metal
                or concrete obstacles (walls, equipment). For a better estimate,
                please adjust the Beacon Range as needed. 
              </Typography>
            </>
          ) : (
            <Typography sx={{ marginTop: "40px" }} variant="h5">
              Please enter values to calculate beacons
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

export default Estimator;
